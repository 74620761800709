import React from 'react'

const CentHand: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_17223_28569)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 2C12.7909 2 11 3.79086 11 6C11 8.20914 12.7909 10 15 10C17.2091 10 19 8.20914 19 6C19 3.79086 17.2091 2 15 2ZM9 6C9 2.68629 11.6863 0 15 0C18.3137 0 21 2.68629 21 6C21 9.31371 18.3137 12 15 12C11.6863 12 9 9.31371 9 6Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 11H2C2.65081 11 3.72806 11.124 4.80242 11.4598C5.70034 11.7404 6.73139 12.2106 7.4719 13H10C11.3261 13 12.5979 13.5268 13.5355 14.4645C14.4732 15.4021 15 16.6739 15 18V19H7V17H12.8284C12.6807 16.5821 12.4407 16.1981 12.1213 15.8787C11.5587 15.3161 10.7956 15 10 15H6.47951L6.18092 14.5737C5.82377 14.0637 5.10703 13.6504 4.20583 13.3687C3.32994 13.095 2.45419 13 2 13V18.3819L8.01416 21.3885C8.70404 21.7336 9.50055 21.7999 10.2379 21.5732C10.2378 21.5733 10.238 21.5732 10.2379 21.5732L20.7278 18.3452C20.5931 17.9563 20.3416 17.6183 20.0074 17.3774C19.6668 17.132 19.2577 17 18.838 17H16V15H18.838C19.6774 15 20.4956 15.2641 21.1767 15.7549C21.8577 16.2457 22.3671 16.9383 22.6326 17.7347L23.272 19.6548L10.8261 23.4848C9.59712 23.8626 8.26955 23.7526 7.11962 23.1773L0 19.6181V11Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 4V8H14V4H16Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export default CentHand
